<template>
  <div>
    <TopMenuSubheadline :title="title"/>
    <div class="app-content-box bg-gray">
      <div class="wrapper container pt-4 mb-3">
        <p class="text-center"><strong>§ 1. Postanowienia ogólne</strong></p>
        <p>1. Regulamin określa sposób korzystania z portalu bookme.com.pl przez Użytkowników i Osoby Niezarejestrowane.</p>
        <p>2. Strona internetowa bookme.com.pl umożliwia komunikację oraz wymianę informacji, opinii i spostrzeżeń przez Użytkowników Zarejestrowanych.</p>
        <p class="text-center"><strong>§2. Definicje</strong></p>
        <p>1. Na potrzeby niniejszego Regulaminu, użyte poniżej określenia oznaczają:</p>
        <div>
          <ol type="a">
            <li>
              <strong>Administrator:</strong>
              <ul>
                  <li>Bookme spo. z o. o.</li>
                  <li>KRS 0000648188</li>
                  <li>NIP 9471988234</li>
                  <li>Regon 365920060</li>
                  <li>Kapitał zakładowy 10 000,00ZŁ</li>
                  <li>Forma prawna:SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ</li>
                  <li>Adres: ul. PIOTRKOWSKA 122/7 90-006 ŁÓDŹŁÓDZKIE</li>
                  <li>Data rejestracji 2016-11-23</li>
                  <li>Sąd SĄD REJONOWY DLA ŁODZI-ŚRÓDMIEŚCIA W ŁODZI, XX
                      WYDZIAŁ KRAJOWEGO REJESTRU SĄDOWEGO</li>
              </ul>
            </li>
            <li>
                <strong>Aplikacja</strong> - oprogramowanie działajace na urządzeniach przenośnych, takich jak telefony komórkowe, smartfony, palmtopy czy tablety pod którymi Administrator prowadzi Portal, stanowiący platformę internetową będącą własnością Administratora, na którą składa się szereg elementów właściwych Portalom internetowym oraz kompleks usług świadczonych drogą elektroniczną na rzecz Użytkowników Zarejestrowanych;
            </li>
            <li>
                <strong>Dane</strong> - dane informacje dotyczące zidentyfikowanej lub możliwej do zidentyfikowania osoby fizycznej w rozumieniu ustawy z dnia 29 sierpnia 1997 roku o ochronie danych osobowych;
            </li>
            <li>
                <strong>Funkcjonalność/i</strong> - Usługi oferowane Użytkownikom w ramach Portalu, w tym w szczególności: możliwość recenzowania książek oraz dokonywania dyskusji na temat książek, oraz tworzenie zindywidualizowanych biblioteczek (zbioru) książek przez Użytkowników Zarejestrowanych; umożliwienie składania zaproszeń znajomym Użytkowników Zarejestrowanych poprzez Portal
            </li>
            <li>
                <strong>Hasło</strong> - ustalany przez Użytkownika podczas procesu Rejestracji ciąg znaków służący późniejszej autoryzacji Użytkownika w Systemie. Hasło musi składać się z od 6 do 30 znaków alfanumerycznych.
            </li>
            <li>
                <strong>Konto Użytkownika</strong> - przypisany do Użytkownika Zarejestrowanego zbiór danych w Portalu, zawierający dane Użytkownika Zarejestrowanego niezbędne do jego autoryzacji w Portalu oraz komunikowania się z innymi Użytkownikami Zarejestrowanymi i Użytkownikami. Konto Użytkownika tworzone jest na bieżąco i jest aktualizowane na podstawie Danych dostarczanych przez Użytkownika Zarejestrowanego lub w wyniku przekształcenia profilu Autora, Wydawcy lub Księgarni w Konto;
            </li>
            <li>
                <strong>Księgarnia</strong> - osoba fizyczna, prawna lub jednostka organizacyjna nieposiadająca osobowości prawnej, ale której odrębna ustawa przyznaje zdolność prawną, która w ramach prowadzonej przez siebie działalności prowadzi detaliczną lub hurtową sprzedaż książek, czasopism, nut, map itp.;
            </li>
            <li>
                <strong>Login</strong> - adres poczty elektronicznej Użytkownika Zarejestrowanego, służący jego identyfikacji i wraz z Hasłem koniecznym do dokonania autoryzacji Użytkownika w trakcie uzyskiwania dostępu do Systemu;
            </li>
            <li>
                <strong>Prawa</strong> - autorskie prawa osobiste, autorskie prawa majątkowe, uprawnienie do zezwalania na wykonywanie praw zależnych, patenty, dodatkowe prawa ochronne na wynalazki, prawa ochronne na wzory użytkowe lub znaki towarowe lub prawa z rejestracji na wzory przemysłowe, topografie układów scalonych lub oznaczenia geograficzne, dobra osobiste bądź prawa o podobnym charakterze (odpowiednio);
            </li>
            <li>
                <strong>Regulamin</strong> - niniejszy regulamin określający warunki korzystania z Portalu;
            </li>
            <li>
                <strong>Rejestracja</strong> - jednorazowa czynność dokonywana przez Użytkownika w Portalu,
                polegająca na założeniu przez Użytkownika Konta Użytkownika oraz zdefiniowaniu
                Loginu i Hasła;
            </li>
            <li>
                <strong>Portal</strong> - strona www, znajdująca się pod adresem domeny internetowej
                bookme.com.pl, a także innymi adresami internetowymi, pod którymi Administrator prowadzi Portal, stanowiący platformę internetową będącą własnością Administratora, na którą składa się szereg elementów właściwych Portalom internetowym oraz kompleks usług świadczonych drogą elektroniczną na rzecz Użytkowników Zarejestrowanych;
            </li>
            <li>
                <strong>Strony</strong> - Usługodawca i Użytkownik Zarejestrowany;
            </li>
            <li>
                <strong>System</strong> - zespół współpracujących ze sobą urządzeń informatycznych i
                oprogramowania, zapewniający przetwarzanie i przechowywanie, a także wysyłanie i odbieranie danych poprzez sieci telekomunikacyjne za pomocą właściwego dla danego rodzaju sieci telekomunikacyjnej Urządzenia końcowego;
            </li>
            <li>
                <strong>Umowa</strong> - umowa o świadczenie Usług/Funkcjonalności zawierana podczas Rejestracji przez Użytkownika z Administratorem;
            </li>
            <li>
                <strong>Urządzenie końcowe</strong> - urządzenie elektryczne lub elektroniczne przeznaczone do zapewniania telekomunikacji przeznaczone do podłączenia bezpośrednio lub pośrednio do zakończeń sieci telekomunikacyjnej;
            </li>
            <li>
                <strong>Usługa</strong> - usługa świadczona bez jednoczesnej obecności stron (na odległość), odbywająca się poprzez przekaz danych na indywidualne żądanie Użytkownika lub
                Użytkownika Zarejestrowanego, przesyłanych i otrzymywanych za pomocą urządzeń do elektronicznego przetwarzania, włącznie z kompresją cyfrową, i przechowywania danych, która jest w całości nadawana, odbierana lub transmitowana za pomocą sieci telekomunikacyjnej w rozumieniu ustawy z dnia 16 lipca 2004 r. – Prawo telekomunikacyjne;
            </li>
            <li>
                <strong>Użytkownik Zarejestrowany</strong> - Użytkownik, który utworzył Konto w wyniku prawidłowo przeprowadzonej Rejestracji, uprawniony do korzystania z Funkcjonalności Portalu. W przypadku Kont utworzonych dla Autorów, Wydawców lub Księgarń, Administrator ma prawo przekazać Konto utworzone przez innego Użytkownika Zarejestrowanego właściwemu Autorowi, Wydawcy lub Księgarni;
            </li>
            <li>
                <strong>Wymogi techniczne</strong> - wymagania techniczne po stronie Użytkownika lub Użytkownika Zarejestrowanego niezbędne do współpracy z Systemem Administratora;
            </li>
            <li>
                <strong>Zawartość</strong> - wszelkie treści umieszczone w Portalu;
            </li>
            <li>
                <strong>Zgoda</strong> - oświadczenie woli, którego treścią jest wyraźna zgoda na przetwarzanie
                Danych osoby składającej oświadczenie wraz z wyraźną zgodą na otrzymywanie
                informacji handlowej od Administratora lub jego kontrahentów;
            </li>
            <li>
                <strong>Znaki</strong> - wszelkie odnoszące się do Portalu informacje zastrzeżone oraz wszystkie
                elementy Portalu poddane ochronie na podstawie obowiązującego prawa.
            </li>
          </ol>
          <p>2. Wyrażenia lub zwroty niezdefiniowane w Regulaminie będą mieć znaczenie przypisywane im przez prawo, a przy braku takiej definicji powinny być rozumiane zgodnie ze znaczeniami obowiązującymi w branży teleinformatycznej lub prawniczej.</p>
          <p class="text-center"><strong>§3. Zasady korzystania z Portalu</strong></p>
          <p>1. Administrator jako właściciel i zarządca Portalu zobowiązuje się do dołożenia wszelkich starań celem zapewnienia działania Portalu bez jakichkolwiek zakłóceń, jednakże Administrator nie ponosi żadnej odpowiedzialności za zakłócenia spowodowane siłą wyższą lub niedozwoloną ingerencją Użytkowników, Użytkowników Zarejestrowanych lub osób trzecich.</p>
          <p>2. Wszelkie działania Użytkowników w ramach Portalu powinny być zgodne z przepisami obowiązującymi na terytorium Rzeczypospolitej Polskiej oraz dobrymi obyczajami w tym w szczególności zakazane jest dostarczanie przez Użytkowników treści o charakterze bezprawnym oraz:</p>
          <ol type="a">
            <li>zakaz pisania wulgaryzmów;</li>
            <li>zakaz spamowania (m.in. wysyłania niechcianych linków do stron);</li>
            <li>nakaz zapoznania się z wykazem FAQ przed zadaniem pytania;</li>
            <li>stosowanie się do reguł pisania obowiązujących w danej grupie, np. sposób kodowania
                polskich liter, zasad cytowania,</li>
            <li>zakaz pisania nie na temat (OT – off-topic);</li>
            <li>zakaz odpowiadania nad cytowanym fragmentem (top-posting);</li>
            <li>zakaz ciągłego pisania wiadomości wielkimi literami;</li>
            <li>zakaz nadużywania interpunkcji;</li>
            <li>zakaz prowokowania kłótni, czyli trollowania.</li>
          </ol>
          <p>3. Użytkownik Zarejestrowany wyłącznie za wyraźną zgodą Administratora może kopiować, powielać lub w jakikolwiek inny sposób wykorzystywać w całości lub we fragmentach informacje, dane lub inne treści za wyjątkiem przypadków dozwolonego użytku wynikających z ustawy z dnia 4 lutego 1994 r. o prawie autorskim i prawach pokrewnych.</p>
          <p>4. W przypadku naruszenia zasad korzystania z Portalu, Administrator może czasowo zawiesić konto Użytkownika Zarejestrowanego. W tym celu zobowiązuje się niezwłocznie, jednak nie później niż w terminie 5 (pięciu) dni roboczych, skontaktować się z Użytkownikiem Zarejestrowanym i poinformować o zachowaniu, które spowodowało zawieszenie uprawnień. Użytkownik Zarejestrowany ma prawo odpowiedzieć oraz wyjaśnić swoje zachowanie. Administrator zobowiązuje się rozpatrzeć odpowiedź Użytkownika Zarejestrowanego i cofnąć zawieszenie uprawnień lub podtrzymać swoją decyzję o zawieszeniu. Administrator w przypadku podtrzymania decyzji o zawieszeniu zobowiązuje się ją uzasadnić. Użytkownik Zarejestrowany, którego uprawnienia związane z dokonywaniem przez niego zmian w Portalu zostały zawieszone, może w każdym terminie skierować wniosek do Administratora o cofnięcie decyzji o zawieszeniu uprawnień.</p>
          <p class="text-center"><strong>§ 4. Przedmiot usługi</strong></p>
          <p>1. Poprzez Portal - tworzony, kontrolowany i obsługiwany przez Administratora - Użytkownikom oferowany jest dostęp do Funkcjonalności bądź możliwość korzystania z Zawartości - zgodnie z Regulaminem lub ewentualnymi dodatkowymi warunkami.</p>
          <p>2. Podstawowym przedmiotem Portalu jest udostępnienie w sieci Internet systemu prezentacji i wymiany informacji o Użytkownikach Zarejestrowanych oraz ich zainteresowaniach literackich, prowadzonego i utrzymywanego przez Administratora, w celu umożliwienia w ten sposób Użytkownikom Zarejestrowanym oceny i komentowania książek i innych publikacji. Za pośrednictwem Portalu świadczone są lub mogą być świadczone w przyszłości, odpłatne i nieodpłatne usługi na rzecz Użytkowników i Użytkowników Zarejestrowanych związane z przedmiotem Portalu.</p>
          <p>3. Portal zawiera Zawartość pochodzącą od Użytkowników, Administratora bądź jego kontrahentów, przy czym Administrator nie jest inicjatorem przekazu danych składających się na bądź będących elementem Zawartości nieoznaczonej w sposób niebudzący wątpliwości jako Zawartość pochodząca od Administratora, nie wybiera odbiorcy przekazu takich danych jak również nie wybiera ani nie modyfikuje informacji zawartych w takim przekazie.</p>
          <p class="text-center"><strong>§ 5. Oświadczenie Użytkowników</strong></p>
          <p>1. Zabrania się korzystania z Kont innych Użytkowników Zarejestrowanych.</p>
          <p>2. Dokonując prawidłowej Rejestracji Użytkownik Zarejestrowany wyraża zgodę na wykorzystywanie podanych przez niego danych osobowych i informacji przez Administratora w celach marketingowych, informacyjnych, statystycznych oraz publikacji w takich mediach jak Internet, sieci telekomunikacyjne, prasa, radio, telewizja, telefonia komórkowa i stacjonarna, przy czym publikacja w mediach może odbywać się tylko w taki sposób, aby prezentowane informacje nie pozwalały na powiązaniem z imieniem i nazwiskiem lub sposobem bezpośredniego kontaktu z Użytkownikiem Zarejestrowanym. Zgoda powyższa obejmuje również zezwolenie udzielone Administratorowi na udostępnianie danych osobowych kontrahentom Administratora w celach określonych powyżej.</p>
          <p>3. W procesie Rejestracji Użytkownik może wyrazić ponadto zgodę na otrzymywanie informacji handlowych, reklamowych i marketingowych od Administratora oraz od jego kontrahentów. Użytkownik Zarejestrowany w każdej chwili może zrezygnować z ich otrzymywania.</p>
          <p>4. Udostępniając treści w Portalu Użytkownik Zarejestrowany:</p>
          <ol>
            <li>oświadcza, iż jest autorem zamieszczonej treści bądź posiada uprawnienia i zgody wymagane przepisami prawa do tego, aby mogły być one zamieszczone jako Zawartość w Portalu,</li>
            <li>wyraża nieodwołalną zgodę na udostępnianie i zamieszczenie przez Administratora powyższych treści bez ich istotnej modyfikacji, oraz wyraża zgodę na konwersję formatu pliku zamieszczonych danych,</li>
            <li>oświadcza, że udziela nieodwołalnej Administratorowi bezterminowej i nieodpłatnej licencji na korzystanie, utrwalanie i zwielokrotnianie zamieszczanych przez Użytkownika Zarejestrowanego treści, ich utrwalanie i zwielokrotnianie dowolną techniką, w celach marketingowych, informacyjnych, statystycznych oraz publikacji w takich mediach jak Internet, sieci telekomunikacyjne, prasa, radio, telewizja, telefonia komórkowa i stacjonarna,</li>
            <li>Recenzent, w rozumieniu ustawy z dnia 4 lutego 1994 r. o prawie autorskim i prawach pokrewnych, zobowiązany jest do napisania i umieszczania oficjalnej recenzji książki w pierwszej kolejności w Portalu, a w przypadku chęci zamieszczania treści utworu poza Portalem, może zamieścić go wraz z podaniem adresu internetowego lub logotypy Portalu lub hiperłącza bezpośrednio kierującego do treści recenzji zamieszczonej,</li>
            <li>zobowiązuje się w przypadku skierowania przez podmioty trzecie jakichkolwiek roszczeń do Administratora, odnośnie naruszenia Praw do opublikowanych przez siebie treści, złożyć pisemne oświadczenie odpowiedniej treści, które uwolni Administratora od jakiejkolwiek odpowiedzialności w tym zakresie, a także, w razie konieczności, wstąpić do sprawy w miejsce Administratora lub przystąpić do sprawy po jego stronie jako interwenient uboczny, zwracając przy tym Administratorowi na jego pierwsze żądanie, wszelkie poniesione przez Administratora wydatki związane z postępowaniem sądowym, w tym w szczególności wydatki poniesione na opłacenie zastępstwa procesowego, doradztwa prawnego oraz zasądzonych odszkodowań.</li>
          </ol>
          <p>5. Odwołanie zgody wyrażonej w ust. 4 pkt b i c nastąpić może jedynie w formie pisemnej za uprzednią pisemną zgodą Administratora.</p>
          <p>6. Użytkownik i Użytkownik Zarejestrowany zobowiązuje się do niepodejmowania działań, które mogłyby utrudnić lub uniemożliwić funkcjonowanie Portalu. Użytkownik Zarejestrowany zobowiązuje się nie podejmować jakichkolwiek działań, poprzez które osoba lub podmiot je realizujący stara się wpływać na innych Użytkowników lub Użytkowników Zarejestrowanych, działać na ich szkodę, szkodę Administratora lub innych podmiotów trzecich. Użytkownik Zarejestrowany zobowiązuje się nie zamieszczać w Portalu treści naruszających polskie lub międzynarodowe przepisy prawa, dobre obyczaje lub normy moralne, obrażających godność lub naruszających dobra osobiste innych osób, popierające radykalne postawy społeczne lub głoszące takie poglądy, w szczególności treści dotyczących dyskryminacji rasowej, etnicznej, ze względu na płeć, religię, wiek, wyznanie lub pochodzenie.</p>
          <div>
            <p class="text-center"><strong>§6. Dostęp do Portalu</strong></p>

            <p>1. Administrator umożliwia dostęp do Portalu oraz korzystanie z Funkcjonalności jedynie pod warunkiem akceptacji wszystkich zapisów Regulaminu oraz Polityki Prywatności. Akceptacja Regulaminu oraz Polityki Prywatności, dokonywana w procesie Rejestracji, jest równoznaczna z zawarciem Umowy.</p>
            <p>2. Administrator udostępnia Regulamin oraz Politykę Prywatności Użytkownikom nieodpłatnie przed zawarciem Umowy w sposób umożliwiający pozyskanie, odtwarzanie i utrwalanie ich treści za pomocą Systemu, którym posługuje się Użytkownik. Brak akceptacji zapisów Regulaminu oraz Polityki Prywatności lub zmian tychże dokumentów oznacza brak możliwości korzystania z Funkcjonalności Portalu.</p>
            <p>3. Przeglądanie Portalu oraz części jego Zawartości może być udostępnione dla każdego Użytkownika, przy czym zakres dostępu dla tych osób może zostać w każdym czasie i w dowolnym zakresie ograniczany przez Administratora bez podawania przyczyn.</p>
          </div>
          <div>
            <p class="text-center"><strong>§7. Rejestracja Konta</strong></p>
            <p>1. Rejestracja Konta w Portalu odbywa się poprzez podanie w formularzu rejestracyjnym następujących danych: Loginu oraz Hasła, a także akceptacji niniejszego Regulaminu oraz Polityki Prywatności.</p>
            <p>2. Użytkownik dokonując Rejestracji i akceptując Regulamin oświadcza, iż:</p>
            <ol>
                <li>jest pełnoletni oraz nie został ubezwłasnowolniony w jakimkolwiek zakresie, bądź ma ukończone 13 lat i posiada zgodę swojego przedstawiciela ustawowego na dokonywanie ważnych czynności prawnych przy wykorzystaniu Portalu;</li>
                <li>bierze na siebie odpowiedzialność za: 1) utrzymanie w tajemnicy danych dotyczących jego Konta Użytkownika, w tym hasła, 2) ograniczenie dostępu osób trzecich do Urządzeń końcowych oraz 3) za wszystkie czynności wykonywane w ramach bądź przy wykorzystaniu jego Konta, w tym modyfikację Zawartości Portalu.</li>
            </ol>
          </div>
          <div>
            <p class="text-center"><strong>§8. Dane Użytkownika Zarejestrowanego</strong></p>

            <p>1. Wszystkie dane podane podczas Rejestracji mogą być zmienione przez Użytkownika poprzez edycję profilu. Informacje przekazywane przez Użytkownika w procesie Rejestracji do Portalu lub przez Użytkownika Zarejestrowanego podczas edycji profilu mogą być moderowane przez Administratora, co oznacza, iż Administrator może odmówić opublikowania ich w Portalu, a tym samym zablokować Konto Użytkownika, jeżeli podawane przez niego Dane osobowe lub inne informacje będą niedozwolone ze względu na polskie lub międzynarodowe przepisy prawa, treść niniejszego Regulaminu oraz dobre obyczaje lub naruszają moralność publiczną.</p>
            <p>2. Użytkownik Zarejestrowany może nieobowiązkowo udostępnić niektóre swoje Dane osobowe, takie jak nazwisko, miejsce zamieszkania lub zainteresowania. Powyższe Dane są jawne dla innych Użytkowników Zarejestrowanych Portalu. Brak udostępnienia powyższych informacji może w pewnym stopniu ograniczyć dostępność Funkcjonalności.</p>
            <p>3. Domyślnie Dane osobowe wprowadzone przez Użytkownika Zarejestrowanego są widoczne dla pozostałych Użytkowników. Zakres dostępności powyższych Danych Użytkownik Zarejestrowany może określić w ustawieniach w ramach Konta Użytkownika.</p>
          </div>
          <div>
            <p class="text-center"><strong>§9. Ochrona Danych Użytkownika</strong></p>

            <p>1. Przetwarzanie Danych Użytkownika podanych w procesie Rejestracji oraz Danych udostępnionych w utworzonym profilu przez Użytkownika Zarejestrowanego odbywa się za Zgodą Użytkownika. Administrator przetwarza Dane zgodnie z Polityką Prywatności oraz właściwymi przepisami obowiązującymi na terytorium Rzeczypospolitej Polskiej, w tym ustawą z dnia 29 sierpnia 1997 r. o ochronie danych osobowych oraz ustawą z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną. Baza danych osobowych Użytkowników Zarejestrowanych podlega ochronie prawnej. Każdy Użytkownik Zarejestrowany ma prawo wglądu do swoich danych przetwarzanych przez Administratora, posiada prawo do poprawiania tych danych oraz do żądania zaniechania ich przetwarzania poprzez usunięcie z
                            bazy danych osobowych. Wyraźnie wyrażone oświadczenie woli o żądaniu zaniechania przetwarzania danych osobowych lub ich usunięcia skierowane do Administratora stanowi wyłączną podstawę usunięcia Konta Użytkownika.</p>
          </div>
          <div>
            <p class="text-center"><strong>§10. Uwarunkowania techniczne</strong></p>
            <p>1. Do korzystania z Portalu, Zawartości lub Funkcjonalności niezbędne jest posiadanie przez Użytkownika lub Użytkownika Zarejestrowanego urządzenia końcowego pozwalającego na korzystanie z Internetu, skrzynki pocztowej poczty elektronicznej (e-mail) oraz przeglądarki zasobów internetowych umożliwiającej wyświetlanie stron www. Zaleca się korzystanie z następujących przeglądarek internetowych w następujących wersjach lub nowszych z włączoną obsługą plików „cookies”:</p>
            <ol>
              <li>Internet Explorer 7,</li>
              <li>Mozilla Firefox 3,</li>
              <li>Opera 10,</li>
              <li>Google Chrome 8.</li>
            </ol>
            <p>2. Do korzystania z Aplikacji Mobilnej Bookme, niezbędne jest posiadanie przez Użytkownika lub Użytkownika Zarejestrowanego urządzenia końcowego z systemem operacyjnym iOS lub Android pozwalającego na korzystanie z Internetu.</p>
            <p>Użytkownik i Użytkownik Zarejestrowany wyrażają zgodę na to, że Administrator może przechowywać Dane bądź inne informacje dotyczące ich Urządzeń końcowych w formie oprogramowania typu „cookies”, z użytkowaniem których wiąże się funkcjonowanie Portalu. Cookies to informacje tekstowe, wysyłane przez Portal www i zapisywane po stronie Użytkownika i Użytkownika Zarejestrowanego, mające na celu umożliwienie Administratorowi podnoszenie jakości usług oraz zwiększanie zadowolenia Użytkownika i Użytkownika Zarejestrowanego dzięki przechowywaniu jego preferencji poruszania się po Portalu. Ograniczenie lub zablokowanie plików „cookies” przez przeglądarkę internetową stosowaną przez Użytkownika lub Użytkownika Zarejestrowanego nie powoduje niemożności uczestnictwa w Portalu, jednakże może to powodować utrudnienia lub nieprawidłowości w jego funkcjonowaniu, za które Administrator nie ponosi odpowiedzialności. Dlatego zalecane jest stosowanie oprogramowania z włączoną obsługą plików „cookies”.</p>
          </div>
          <div>
            <p class="text-center"><strong>§11. Korzystanie ze Znaków</strong></p>

            <p>1. Administrator oraz jego kontrahenci mają wyłączne prawo do korzystania ze Znaków. Uprawnienie do korzystania ze Znaków może zostać przeniesione na podmiot trzeci jedynie w postaci umowy, łączącej podmiot uprawniony do rozporządzania Znakiem z podmiotem trzecim. Na czas trwania Umowy pomiędzy Administratorem a Użytkownikiem Zarejestrowanym, Użytkownik Zarejestrowany ma prawo do korzystania ze Znaku jedynie w sposób określony obowiązującym prawem, w tym w ramach dozwolonego użytku. Użytkownicy nie mogą korzystać ze Znaków w inny sposób bez uprzedniej pisemnej oraz wyraźnej zgody Administratora.</p>
          </div>
          <div>
            <p class="text-center"><strong>§12. Usunięcie Konta Użytkownika</strong></p>

            <p>1. Użytkownik Zarejestrowany może w każdej chwili usunąć swoje Konto Użytkownika z Portalu dokonując określonych czynności określonych we wskazówkach znajdujących się w Pomocy. Usunięcie Konta Użytkownika powoduje usunięcie jego danych osobowych. Usunięcie Konta Użytkownika następuje na stałe i bezpowrotnie. Usunięcie Konta Użytkownika pozostaje bez wpływu na udzielone Administratorowi prawo do korzystania z treści zamieszczonych w Portalu. Usunięcie Konta Użytkownika powoduje, iż dalsze świadczenie usługi jest niemożliwe i jest równoznaczne z rozwiązaniem Umowy. Na
                            podstawie uzgodnień pomiędzy Administratorem a Autorem, Wydawcą lub Księgarnią, Strony mogą przyjąć, że usunięcie Konta Autora, Konta Wydawcy lub Konta Księgarni może być związane z usunięciem części danych, informacji lub materiałów audiowizualnych znajdujących się Portalu.</p>
            <p>2. Wraz z momentem rozwiązania Umowy Login oraz Hasło Użytkownika Zarejestrowanego zostają usunięte oraz Użytkownik traci wszystkie prawa, tytuły i korzyści ze wszystkich ewentualnych akcji o cechach promocji lub konkursu, w których uczestniczył w ramach Portalu.</p>
            <p>3. Rozwiązanie Umowy nie zwalnia Użytkownika Zarejestrowanego z obowiązku wywiązania się z wszelkich ewentualnych zobowiązań wobec Administratora lub osób trzecich, powstałych w ramach Portalu lub w związku z Portalem, co do których Użytkownik Zarejestrowany nie został zwolniony przez wierzyciela w sposób nie budzący wątpliwości w formie pisemnej.</p>
          </div>
          <div>
            <p class="text-center"><strong>§13. Wypowiedzenie Umowy przez Administratora</strong></p>
            <p>1. Administrator uprawniony jest wypowiedzieć Umowę ze skutkiem natychmiastowym w przypadku, jeżeli:</p>
            <ol>
                <li>Użytkownik Zarejestrowany korzysta z Portalu, jego Zawartości lub Funkcjonalności w sposób sprzeczny z Umową,</li>
                <li>Użytkownik Zarejestrowany zamieszcza treści, do których wykorzystywanie Portalu jest niedozwolone, w szczególności poprzez rozpowszechnianie treści pornograficznych, uprawianie działalności komercyjnej, reklamowej lub promocyjnej, rozsyłanie spamu i niezamówionej informacji handlowej,</li>
                <li>Użytkownik Zarejestrowany udostępnia przynależące mu Konto Użytkownika do używania osobom trzecim bez pisemnej zgody Administratora.</li>
            </ol>

            <p>2. Zgłoszenia podmiotu trzeciego dotyczące naruszenia w ramach lub przy wykorzystaniu Portalu przez Użytkownika Zarejestrowanego przysługujących mu praw powinno spełniać kumulatywnie następujące kryteria:</p>
            <ol>
                <li>zawiera elektroniczny lub fizyczny podpis osoby, której przysługują prawa bądź działającej w imieniu i na rzecz podmiotu, któremu przysługują Prawa,</li>
                <li>zawiera precyzyjny opis Zawartości naruszającej wyżej wymienione Prawa wraz z załączeniem stosownych dokumentów,</li>
                <li>zawiera precyzyjny opis miejsca w Portalu, gdzie ma miejsce naruszenie (wraz z załączeniem zrzutów ekranu Portalu),</li>
                <li>zawiera dokładny adres, numer telefonu stacjonarnego oraz adres poczty elektronicznej (e-mail) podmiotu zgłaszającego naruszenie,</li>
                <li>zawiera oświadczenie podmiotu zgłaszającego, złożone pod groźbą odpowiedzialności cywilnej oraz karnej, że informacje podane w zgłoszeniu są w najwyższym stopniu dokładne oraz zgodne ze Stanem faktycznym i mają na celu tylko i wyłącznie ochronę Praw, nie są zaś skierowane na wywołanie jakiejkolwiek szkody Administratorowi lub Użytkownikowi Zarejestrowanemu, któremu naruszono któregokolwiek z Praw,</li>
                <li>zawiera oświadczenie podmiotu zgłaszającego, że jest on podmiotem, któremu powyższe Prawa przysługują lub działa w imieniu i na rzecz takiego podmiotu, wraz z załączeniem prawidłowo sporządzonego pełnomocnictwa lub jego czytelnej kopii elektronicznej.</li>
            </ol>

            <p>3. W sytuacjach rodzących uzasadnione wątpliwości co do prawidłowości zgłoszenia, określonego w ust. 1, Administrator może poprzestać na czasowym zablokowaniu dostępu do Zawartości, której dotyczy zgłoszenie. Usunięcie Konta Użytkownika lub zablokowanie dostępu do Zawartości nie oznacza jednoczesnego uznania, iż powyższe treści stanowią Zawartość naruszającą prawa podmiotu zgłaszającego.</p>
            <p>4. Zgłoszenie, które nie spełnia prawidłowo wszystkich kryteriów wymienionych w ust. 1 powyżej, nie może być traktowane jako wiarygodna wiadomość o bezprawnym charakterze danych lub związanej z nimi działalności.</p>
            <p>5. Wszelkie zgłoszenia powinny być składane mailem na adres Administratora.</p>
            <p>6. Usunięcie Konta Użytkownika przez Administratora w przypadkach określonych w Regulaminie jest potwierdzane przez Administratora poprzez przesłanie odpowiedniej informacji na adres poczty elektronicznej (e-mail) Użytkownika Zarejestrowanego, aktywny w chwili usunięcia Konta.</p>
          </div>
          <div>
            <p class="text-center"><strong>§14. Wyłączenie odpowiedzialności Administratora</strong></p>
            <p>1. Administrator nie ponosi odpowiedzialności za sposób korzystania przez Użytkownika lub Użytkownika Zarejestrowanego z Portalu i skutki korzystania z Portalu, jeżeli korzystanie nastąpiło w sposób sprzeczny z postanowieniami niniejszego Regulaminu.</p>
            <p>2. Administrator nie ponosi odpowiedzialności za szkody poniesione przez Użytkownika spowodowane niezależnymi od Administratora zagrożeniami występującymi w sieci Internet. Ponadto Administrator nie ponosi odpowiedzialności za przerwy w świadczeniu dostępu do Portalu zaistniałe z przyczyn technicznych (np. w związku z przeprowadzaną konserwacją, przeglądem lub wymianą sprzętu) lub innych przyczyn niezależnych od Administratora.</p>
            <p>3. Administrator zastrzega sobie prawo do zaprzestania udostępniania Portalu, po uprzednim powiadomieniu o tym Użytkowników i Użytkowników Zarejestrowanych na stronach Portalu.</p>
            <p>4. Administrator nie ponosi odpowiedzialności za usługi świadczone za pośrednictwem Portalu przez podmioty trzecie, które świadczą usługi na rzecz Użytkowników we własnym imieniu i na własny rachunek, na zasadach i na mocy porozumień zawartych z Administratorem lub jego kontrahentami. Określenie reguł świadczenia takich usług należy do podmiotu świadczącego te usługi i podmiot ten jest wyłącznie za nie odpowiedzialny. Ewentualne zażalenia kierowane do Administratora w tym zakresie będą przekazywane tym podmiotom.</p>
            <p>5. Administrator zastrzega, iż zakres Portalu, jego Zawartość oraz Funkcjonalność może być w każdej chwili zmieniana przez Administratora. Administrator będzie informował na łamach Portalu o każdej zmianie zakresu udostępnianych funkcji z odpowiednim wyprzedzeniem.</p>
          </div>
          <div>
            <p class="text-center"><strong>§15. Zażalenia</strong></p>

            <p>1. Użytkownicy Zarejestrowani mogą składać zażalenia dotyczące spraw związanych z funkcjonowaniem Portalu z wyłącznej winy Administratora. Zażalenia mogą być składane drogą elektroniczną za pomocą wiadomości przesłanej na adres poczty elektronicznej (e- mail) Administratora: kontakt@bookme.com.pl.</p>
            <p>2. Zażalenie powinno zawierać następujące informacje: a) imię i nazwisko oraz adres zamieszkania albo nazwę oraz siedzibę Użytkownika Zarejestrowanego; b) określenie przedmiotu zażalenia ; c) przedstawienie okoliczności uzasadniających reklamację oraz załączenie wszelkich posiadanych dokumentów oraz informacji wykazujących zasadność roszczeń reklamacyjnych.</p>
            <p>3. W przypadku, gdy złożone Zażalenie nie spełnia warunków określonych w ust. 1 powyżej, Administrator, o ile uzna, że jest to konieczne do prawidłowego rozpatrzenia zażalenia, wzywa Użytkownika Zarejestrowanego drogą elektroniczną do uzupełnienia, określając termin, nie krótszy niż 7 dni, i zakres tego uzupełnienia, z pouczeniem, że nieuzupełnienie zażalenia w określonym terminie spowoduje pozostawienie zażalenia bez rozpoznania. Po bezskutecznym upływie wyznaczonego terminu, zażalenia pozostawia się bez rozpoznania.</p>
            <p>4. Zażalenie może być złożone w terminie dwunastu miesięcy od dnia zdarzenia, którego dotyczy zażalenie z wyłącznej winy Administratora. Zażalenie złożone po upływie terminu, o którym mowa powyżej, pozostawia się bez rozpoznania.</p>
            <p>5. Administrator zastrzega sobie prawo do ingerencji w strukturę techniczną Konta Użytkownika celem zdiagnozowania nieprawidłowości w funkcjonowaniu usług Portalu, a także może dokonywać zmian i w każdy inny sposób oddziaływać na stronę techniczną Konta Użytkownika, w celu jego modyfikacji lub przywrócenia prawidłowego działania samego Konta Użytkownika lub Portalu.</p>
            <p>6. Administrator udziela odpowiedzi na zażalenie drogą elektroniczną w terminie 30 (trzydzieści) dni od dnia jej złożenia. Odpowiedź na zażalenie zostanie przesłana do Użytkownika Zarejestrowanego na adres podany przez niego w zażaleniu. Administrator zastrzega sobie prawo do wydłużenia powyższego terminu maksymalnie o 30 (trzydzieści) dni w przypadku, gdy rozpoznanie zażalenia wymaga wiadomości specjalnych lub napotka przeszkody z przyczyn niezależnych od Administratora (takie jak awarie sprzętu lub sieci internetowej). Administrator ponadto zastrzega, że rozpatrzenie zażalenia może wymagać uzyskania od Użytkownika dodatkowych wyjaśnień – czas udzielania wyjaśnień przez Użytkownika każdorazowo przedłuża okres rozpoznania zażalenia.</p>
            <p>7. Po wyczerpaniu postępowania zażalenia Użytkownik Zarejestrowany może skierować sprawę na drogę postępowania przed sądem powszechnym.</p>
          </div>
          <div>
            <p class="text-center"><strong>§16. Zmiana Regulaminu</strong></p>

            <p>1. Regulamin, w tym polityki oraz warunki, o których mowa w tym Regulaminie, stanowią całość Umowy i regulują korzystanie przez Użytkownika z Portalu i Aplikacji, zastępując wszelkie wcześniejsze umowy pomiędzy Użytkownikiem i Administratorem.</p>
            <p>2. Administrator zastrzega sobie prawo do zmiany sposobu funkcjonowania Portalu, Funkcjonalności Portalu lub treści Regulaminu, Polityki Prywatności lub Dodatkowych warunków w dowolnym czasie według własnego uznania. Wszelkie zmiany Regulaminu, Prywatności lub Dodatkowych warunków wchodzą w życie w ciągu 7 (siedmiu) dni od opublikowania w ramach Portalu lub – w przypadku Dodatkowych warunków – w terminie określonym w tych warunkach.</p>
            <p>3. Aktualna wersja Regulaminu jest dostępna pod następującym adresem: bookme.com.pl/regulamin</p>
            <p>4. O ile nie zostanie to w sposób wyraźny określone inaczej, wszelkie nowe funkcje w ramach Portalu bądź Funkcjonalności podlegają Regulaminowi.</p>
          </div>
          <div>
            <p class="text-center"><strong>§17. Postanowienia końcowe</strong></p>

            <p>1. Administrator zastrzega sobie prawo do anonimowej publikacji treści kierowanych przez Użytkowników lub Użytkowników Zarejestrowanych do Administratora, a dotyczących kwestii związanych z funkcjonowaniem Portalu, udzielanymi poradami i innych, co do których Administrator uzna, że odpowiedzi na te treści są warte upublicznienia.</p>
            <p>2. Użytkownicy Zarejestrowani zgadzają się, że Umowa może zostać scedowana przez Administratora, w całości lub w części, na rzecz osoby trzeciej. Użytkownicy Zarejestrowani nie mogą scedować Umowy na rzecz jakichkolwiek podmiotów z uwagi na charakter Konta Użytkownika.</p>
            <p>3. Niniejszy dokument jak również dokumenty w nim powołane podlegają przepisom polskiego prawa, w tym w szczególności przepisom: ustawy z dnia 23 kwietnia 1964 r. Kodeks cywilny (Dz. U. 1964 nr 16, poz. 93 z późn. zm.), ustawy z dnia 29 sierpnia 1997 roku o ochronie danych osobowych (Dz. U. 2002 nr 101, poz. 926 tj. z późn. zm.), ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną (Dz. U. 2002 nr 144, poz.
                1204) oraz ustawy z dnia 4 lutego 1994 r. o prawie autorskim i prawach pokrewnych (Dz. U. 2006 nr 90, poz. 631 tj. z późn. zm.).</p>
            <p>4. Wszelkie spory, które mogą wyniknąć w związku z niniejszym dokumentem będą rozwiązywane w drodze negocjacji pomiędzy Stronami, którzy zobowiązują się działać w dobrej wierze. W przypadku, gdy procedura przewidziana w zdaniu powyżej okaże się nieskuteczna, spór będzie przedstawiony do rozpoznania przez właściwe sądy powszechne z siedzibą w Poznaniu.</p>
            <p>5. Niniejszy Regulamin wchodzi w życie z dniem 20 listopada 2020r.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TopMenuSubheadline from '../components/TopMenuSubheadline'
export default {
  name: 'SettingsRules.',
  components: {
    TopMenuSubheadline
  },
  data () {
    return {
      title: 'Regulamin'
    }
  }
}
</script>
<style lang="scss" scoped>
  .app-content-box {
    min-height: 100vh;
  }
  .wrapper {
    margin-top: 30px;
    padding-bottom: 20px;
    font-size: 13px;
    font-weight: 500;
  }
  ol {
    li {
      margin-bottom: 20px;
    }
    ul {
      li {
        list-style-type: none;
      }
    }
  }
</style>
